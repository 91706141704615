import React from 'react';


class SupportQuestion extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var data = this.props.data
    var self = this

    // Build our question list
    return (
      <div className="content">
        <div className="app-question">
        <h1>{data.title}</h1>
        <h2>{data.question}</h2>
        <div className="app-choices">
          {
            data.options.map((option) => {
              console.log(option)
              switch (option.type) {
                case "app":
                  return (
                    <div className="app-choice-app app-choice">
                      <a onClick={()=>{
                        self.props.onClick(option)
                      }}>
                      <div className="app-choice-icon">
                        <img
                          src={`apps/${option.icon}/icon-128.png`}
                          draggable="false"
                        />
                      </div>
                      {option.label}
                      </a>
                    </div>
                  )
              }
              return (
                <div className="app-choice">
                  <a onClick={()=>{
                    self.props.onClick(option)
                  }}>
                  {
                    option.label
                  }
                  </a>
                </div>
              )
            })
          }
        </div>
        </div>
      </div>
    )
  }
}

export default SupportQuestion;
