import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

// rsuite
import { Container, Header, Content, Footer } from "rsuite"
import { Navbar, Nav, Icon } from "rsuite"
import { Carousel } from "rsuite"

import AppList from './AppList.js';
import AppDetail from './AppDetail.js';
import Privacy from './Privacy.js';
import Support from './Support/Support.js';

import 'rsuite/dist/styles/rsuite-default.css'
import './App.css';
import apps from './data.js';

class Main extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Container>
        <Header>
          <Navbar className="content">
            <Navbar.Header>
              <div className="navbar-brand logo">
                <a href="/">
                  <img src="/flogo60.png" draggable="false"/>
                </a>
              </div>
            </Navbar.Header>
            <Nav pullRight>
              <Nav.Item href="/privacy" componentClass="a" icon={<Icon icon="shield" />}>Privacy</Nav.Item>
              <Nav.Item href="/contact" componentClass="a" icon={<Icon icon="question-circle" />}>Support</Nav.Item>
            </Nav>
          </Navbar>
        </Header>
        <Switch>
          <Route exact path="/privacy*" component={Privacy}/>
          <Route exact path="/contact*" component={Support}/>
          <Route exact path="/app/:app" render={(props) => {
            return (<AppDetail {...props} apps={apps}/>)
          }}/>
          {
            Object.keys(apps).map((app)=>{
              return (
                  <Route exact path={`/${app}.html`} render={(props) => {
                  return (<AppDetail app={app} apps={apps}/>)
                }}/>
              )
            })
          }
          <Route exact path="*">
            <div className="hero">
              <div className="hero-title">Welcome to Samuco</div>
              <div className="hero-subtitle">We made these apps for Mac and iOS</div>
            </div>
            <AppList apps={apps}/>
          </Route>
        </Switch>

        {/*<AppInfo app="folder-forge"/>*/}
        <Footer>
        </Footer>
      </Container>
    )
  }
}

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Main/>
      </div>
    </BrowserRouter>
  );
}

export default App;
