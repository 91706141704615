export default {

  /* Folder Forge */
  "folder-forge": {
    "title": "Folder Forge",
    "category": "Productivity",
    "description": "Create exquisite and unique folders for your computer",
    "rating": 4.3,
    "legacy": true,
    //"mas": "macappstore://itunes.apple.com/us/app/colored-folder-creator/id412516557?mt=12&ls=1",
    "sections": [
      {
        "title": "Bring your folders to life",
        "subtitle": "Create exquisite and unique folders for your computer with over 1000 pre-created images",
        "images": [
          {
            "src": "2560x1600bb-0.png"
          },
          {
            "src": "2560x1600bb-2.png"
          },
          {
            "src": "2560x1600bb-3.png"
          },
          {
            "src": "2560x1600bb-4.png"
          }
        ]
      },
      {
        "title": "Express your creativity",
        "subtitle": "You can even create your own stunning folders from scratch with simplicity and ease",
        "images": [
          {
            "src": "2560x1600bb-1.png"
          }
        ]
      }
    ]
  },

  /* Dock Menus */
  "dock-menus": {
    "title": "Dock Menus",
    "category": "Productivity",
    "description": "Clean up your dock by creating new ones",
    "rating": 2.1,
    "legacy": true,
    //"mas": "macappstore://itunes.apple.com/us/app/dockmenus/id488559908?ls=1&mt=12",
    "sections": [
      {
        "title": "Unclutter your desktop",
        "subtitle": "Dock Menus will make you more organized to help you to complete tasks faster.",
        "images": [
          {
            "src": "1280x800bb-0.png"
          }
        ]
      },
      {
        "title": "Hidden away until you need them",
        "subtitle": "You can pin menus to the side and hide them, so they don't take up precious screen space",
        "images": [
          {
            "src": "1280x800bb-1.png"
          }
        ]
      }
    ]
  },

  /* iDesktop */
  "idesktop": {
    "title": "iDesktop",
    "category": "Productivity",
    "description": "Upgrade your desktop",
    "rating": 2.7,
    "legacy": true,
    //"mas": "macappstore://itunes.apple.com/us/app/idesktop/id450986437?ls=1&mt=12",
    "sections": [
      {
        "title": "Upgrade your desktop",
        "subtitle": "iDesktop lets you have different desktops with different files and different wallpapers",
        "images": [
          {
            "src": "2560x1600bb-1.png"
          }
        ]
      },
      {
        "title": "Declutter your desktop",
        "subtitle": "It's easy to declutter your desktop with iDesktop and bring your organisation to a whole new level.",
        "images": [
          {
            "src": "2560x1600bb-0.png"
          }
        ]
      },
      {
        "title": "Organise your workspace",
        "subtitle": "Create a desktop for work, school, gaming or create a blank desktop for screenshot capture.",
        "images": [
          {
            "src": "2560x1600bb-2.png"
          },
          {
            "src": "2560x1600bb-3.png"
          }
        ]
      },
    ]
  },

  /* Secret Folders */
  "secret-folders": {
    "title": "Secret Folders",
    "category": "Productivity",
    "description": "An easy way to hide your files",
    "rating": 2.9,
    "legacy": true,
    //"mas": "macappstore://itunes.apple.com/us/app/secret-folders/id443800718?ls=1&mt=12",
    "sections": [
      {
        "title": "An easy way to hide your files",
        "subtitle": "Secret Folders allows you to easily create and hide secure password protected folders. It is an essential utility for people who want to protect their privacy. The Secret Folder and its content is invisible and cannot be found by a search or other applications.",
        "images": [
          {
            "src": "1280x800bb-0.png"
          }
        ]
      }
    ]
  },

  /* Mouse Wizard */
  "mousewizard": {
    "title": "MouseWizard",
    "category": "Productivity",
    "description": "Do you want more from your Magic Mouse?",
    "rating": 2.9,
    "legacy": true,
    //"download": "MouseWizard.zip",
    //"paypal": "9688294",
    "sections": [
      {
        "title": "Add a little magic to your mouse",
        "subtitle": "Do you want more from your Magic Mouse? MouseWizard is a simple utility to enhance the use of your Magic Mouse. You can easily customize your Magic Mouse to increase your productivity. By using MouseWizard, you can give your Magic Mouse six extra buttons, 'pinch', 'bloat' and 'tap to click' trackpad gestures, and special features such as 'coverup' to make your Mac go to sleep.",
        "images": [
          {
            "src": "ss-main.png"
          }
        ]
      }
    ]
  }
}
