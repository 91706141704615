import React from 'react';
import apps from '../data.js';

import { Container, Header, Content, Footer } from "rsuite"

import SupportContact from './SupportContact.js';
import SupportQuestion from './SupportQuestion.js';

class Support extends React.Component {
  constructor(props) {
    super(props)

    // Build data
    this.states = {
      "root": {
        "type": "question",
        "title": "How can we help?",
        "options": [
          {
            "label": "😰 I'm having an issue with an app",
            "state": "which-app"
          },
          {
            "label": "🔑 I need a new registration key",
            "state": "new-key"
          },
          {
            "label": "🤔 I want to ask a question",
            "type": "tiny",
            "state": "contact"
          }
        ]
      },
      "which-app": {
        "type": "question",
        "title": "Sorry about that 😰",
        "question": "Which app are you having an issue with?",
        "options": Object.keys(apps).sort(function(a, b) {
          return apps[a].rating < apps[b].rating
        }).map((app) => {
          return {
            "label": apps[app].title,
            "type": "app",
            "icon": app,
            "state": "app-issue"
          }
        }).concat([{
          "label": "Other",
          "type": "tiny",
          "state": "contact"
        }])
      },
      "app-issue": {
        "type": "question",
        "title": "Sorry about that 😰",
        "question": "How can we help with #{APPNAME}?",
        "options": [
          {
            "label": "🤔 I want to ask a question"
          },
          {
            "label": "😧 I want a refund"
          }
        ]
      },
      "app-refund-mas": {

      },
      "app-refund-paypal": {

      },
      "new-key": {

      },
      "contact": {
        "type": "contact",
        "title": "How can we help?",
        "question": "It might take up to 48 hours to receive a response",
      }
    }
    this.state = {
      active: "contact"
    }
  }

  render() {
    var self = this
    var active = this.state.active
    var data = this.states[active]

    // Replace variables
    var variables = {
      "APPNAME": self.state.APPNAME
    }
    Object.keys(variables).forEach(function(variable) {
      if (data.question !== undefined) {
        data.question = data.question.replace("#{"+variable+"}", variables[variable])
      }
      if (data.title !== undefined) {
        data.title = data.title.replace("#{"+variable+"}", variables[variable])
      }
    })

    switch (data.type) {
      case "question":
       return (
         <SupportQuestion data={data} variables={variables} onClick={(option)=>{
           var state = option.state
           if (state === undefined) {
             return
           }
           var APPNAME = self.state.APPNAME
           switch(option.type) {
             case "app":
              APPNAME = option.label;
              break;
           }
           self.setState({
             active: state,
             APPNAME: APPNAME
           })
         }}/>
       )
      case "contact":
      return (
        <SupportContact data={data} variables={variables}/>
      )
    }

    return (
      <div>
        <h1>How can we help?</h1>
        <a></a>
          <h2></h2>
          <p></p>
            <p>MouseWizard</p>
            <p>Folder Forge</p>
            <p>Secret Folders</p>
            <p>Other…</p>


          <p>How can we help you with APPNAME?</p>
            <p>I want a refund</p>
              <p>No worries!</p>
          <p>I want to ask a question</p>

        <a>🔑 I need a new registration key</a>
        <a>🤔 Something else</a>

        <h1>We're still building this</h1>
        <br/>
        <p>In the meantime, please visit <a href="https://samuco.net/contact.html">our old website</a></p>
      </div>
    )
  }
}

export default Support;
