import React from 'react';
import { Icon } from 'rsuite';

class AppDownloadMAS extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <a className="download-btn" href={this.props.href}><span><Icon icon="apple" /> Mac App Store</span></a>
    )
  }
}

export default AppDownloadMAS;
