import React from 'react';

import { Content } from "rsuite"

class Privacy extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Content className="content privacy">
        <h1>Privacy Policy</h1>
        As part of our commitment to protecting your privacy, this statement is designed to provide you with information regarding how Samuco (“we”) may use — and process — the information you share when you use our website. Information is also collected when you communicate with us or utilize our applications.
        <hr/>
        <h2>Your Personal Data We Use</h2>
        <h3>Information you provide directly, via our website or email</h3>
        <p>Samuco collects personal information about you called Personal Data. Your Personal Data is collected when you register or fill in a form on the website, or contact us. This includes information you provide when you use our contact form or email us. The information you typically give us includes your specific data such as name, address and telephone number, e-mail address, payment information, as well as any information about you that is associated with or linked to, or could be linked to, any of the foregoing data. We don't collect any personal data via our apps.</p>

        <h3>Website & App - Log Data</h3>
        <p>We also collect log data, which is information collected whenever you visit a website or launch an app. This log data includes your Internet Protocol address and the date and time of your request. We don't store cookies.</p>

        <h3>Information from Other Sources</h3>
        <p>We also obtain information about you from other sources and combine that information with information we collect from you directly. For example, we collect information about you when you pay for an app using PayPal. The information we obtain from other sources includes your name, address and telephone number, e-mail address, payment information, as well as any information about you that is associated with or linked to, or could be linked to, any of the foregoing data.</p>

        <h3>Recipients of Your Personal Data</h3>
        <p>We do not share your personal data.</p>

        <h3>What about privacy on other web sites?</h3>
        <p>This web site may contain links to other web sites. Some of those web sites may be operated by by third parties. We provide the links for your convenience, but we do not review, control, or monitor the privacy practices of web sites operated by others. We are not responsible for the performance of web sites operated by third parties or for your business dealings with them. Therefore, whenever you leave this web site we recommend that you review each web site's privacy practices and make your own conclusions regarding the adequacy of these practices.</p>

        <h3>How to contact us</h3>
        <p>If you have questions or comments about this Privacy Statement, please use the Support on this web site.</p>
      </Content>
    )
  }
}

export default Privacy;
