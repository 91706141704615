import React from 'react';
import { Content } from "rsuite"
import AppListItem from "./AppListItem.js"

class AppList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var self = this
    var keys = Object.keys(self.props.apps)
    keys.sort(function(a, b) {
      return self.props.apps[a].rating < self.props.apps[b].rating
    })

    return (
      <Content className="content">
      {
        keys.map((app) => {
          return (
            <AppListItem app={app} apps={self.props.apps}/>
          )
        })
      }
      </Content>
    )
  }
}

export default AppList;
