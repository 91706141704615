import React from 'react';
import { Icon } from 'rsuite';

class AppPaypal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post">
        <input type="hidden" name="cmd" value="_s-xclick"/>
        <input type="hidden" name="hosted_button_id" value="9688294"/>
        <button className="download-btn" type="submit" name="submit" alt="PayPal - The safer, easier way to pay online.">
          <span><Icon icon="gift" /> Register</span>
        </button>
        <img style={{display: "flex"}} alt="" border="0" src="https://www.paypal.com/en_AU/i/scr/pixel.gif" width="1" height="1"/>
      </form>

    )
  }
}

export default AppPaypal;
