import React from 'react';
import BlockIcon from '@rsuite/icons/Block';

class AppDownloadLegacy extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <span className="download-btn-disabled"><span><BlockIcon/> No longer available</span></span>
    )
  }
}

export default AppDownloadLegacy;
