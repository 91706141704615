import React from 'react';
import { Rate } from 'rsuite';
import AppDownload from './AppDownload.js';
import AppDownloadMAS from './AppDownloadMAS.js';
import AppDownloadLegacy from './AppDownloadLegacy.js'

class AppListItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const info = this.props.apps[this.props.app]
    return (
      <a className="app-header-lite" href={`/app/${this.props.app}`}>
        <div className="app-icon">
          <img
            src={`apps/${this.props.app}/icon-128.png`}
            draggable="false"
          />
        </div>
        <div className="app-info">
          <div className="app-lite-title">
            <div className="app-title">
            {info.title}
            </div>
            <div className="app-rating">
              <Rate defaultValue={info.rating} allowHalf readOnly size="xs" />
            </div>
          </div>
          <div className="app-description">
          {info.description}
          </div>
        </div>
        <div className="app-actions">
          { (info.mas !== undefined) ? <AppDownloadMAS href={info.mas}/> : (null) }
          { (info.download !== undefined) ? <AppDownload href={`/apps/${this.props.app}/${info.download}`}/> : (null) }
          { (info.legacy !== undefined) ? <AppDownloadLegacy/> : (null)}
        </div>
      </a>
    )
  }
}

export default AppListItem;
