import React from 'react';

import { Input, Button, ButtonToolbar } from "rsuite"
import { Schema, Form, FormGroup, FormControl, ControlLabel, HelpBlock } from 'rsuite';
import ReCAPTCHA from 'react-google-recaptcha';

const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  subject: StringType().isRequired('This field is required.'),
  question: StringType().isRequired('This field is required.'),
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.')
});

class SupportContact extends React.Component {
  constructor(props) {
    super(props)
    this.target = 'https://api.samuco.net/support' // "http://localhost:3000/support"
    this.state = {
      captcha: undefined,
      inflight: false
    }
  }

  storeCaptcha(value) {
    this.setState({
      captcha: value
    })
  }

  render() {
    var data = this.props.data
    var self = this
    if (self.state.complete) {
      return (
        <div className="content">
          <div className="app-contact">
            <h1>🥳 Your question has been sent!</h1>
            <h2>We'll try to get back to you as quickly as we can. Have a nice day!</h2>
          </div>
        </div>
      )
    }
    return (
      <div className="content">
        <div className="app-contact">
          <h1>{data.title}</h1>
          <h2>{data.question}</h2>
          <hr/>
          <Form
            fluid
            model={model}
            formValue={self.state.formValue}
            onChange={(formValue)=>{
              self.setState({
                formValue: formValue
              })
            }}
            onSubmit={(valid)=>{
              if (!valid) {
                return
              }

              // Check the values
              var captcha = self.state.captcha
              var data = self.state.formValue
              data.captcha = captcha

              // Submit our request
              self.setState({
                inflight: true
              }, function() {
                fetch(self.target, {
                  crossDomain: true,
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(data)
                }).then((response)=> {
                  return response.json()
                }).then((response)=> {
                  if (response.success) {
                    self.setState({
                      inflight: false,
                      complete: true
                    })
                    return
                  }

                  self.setState({
                    inflight: false,
                    error: String(response.error || "An unknown error occured")
                  })
                  return
                }).catch((error) => {
                  self.setState({
                    inflight: false,
                    error: String(error)
                  })
                })
              })
            }}
          >
            <FormGroup>
              <ControlLabel>Email</ControlLabel>
              <FormControl name="email" type="email"
              disabled={self.state.inflight}/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Subject</ControlLabel>
              <FormControl name="subject"
              disabled={self.state.inflight}/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Question</ControlLabel>
              <FormControl rows={5} name="question" componentClass="textarea"
              disabled={self.state.inflight}/>
            </FormGroup>
            <FormGroup>
              <ReCAPTCHA
              sitekey="6Ld4MQMbAAAAAE80GTkn_cRy1eoZv1WQ2sGWJxWT"
              onChange={this.storeCaptcha.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              <ButtonToolbar>
                <Button
                loading={self.state.inflight}
                appearance="primary"
                type="submit"
                disabled={self.state.inflight || self.state.captcha === undefined}
                >Send</Button> { self.state.inflight ? <>Sending…</> : ( self.state.error ? (<span style={{color: "red"}}>{self.state.error}</span>) : null) }
              </ButtonToolbar>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
  }
}

export default SupportContact;
